/* Provide sufficient contrast against white background */
:root {
  --primary-color: #009fe3;
  --helpdesk-color: #ef7716;
  --color-in-primary: #fff;
  --boligservice-color: rgb(127, 210, 193);
  --tw-black: #22292F;
  --gray-700: #8A8A8A;
  --gray-500: #E6E6E6;
    --gray-900: #454545;
  --tw-rounded-none: 0;
  --tw-rounded-lg: .5rem;
  --tw-rounded-full: 9999px;
}

html {
  font-family: Roboto, sans-serif
}

/*body {
    background: url(/assets/stripbg.png);
    background-position: top left;
  }*/

.bg-primary {
  background: var(--primary-color);
}

.text-primary {
  color: var(--primary-color);
}

.border-primary {
  border-color: var(--primary-color);
}

.text-in-primary {
  color: var(--color-in-primary);
}

.hover\:text-primary:hover {
  color: var(--primary-color);
}

.group:hover .group-hover\:text-primary {
    color: var(--primary-color);
}


.hover\:bg-primary:hover {
  background: var(--primary-color);
}

.hover\:border-primary:hover {
  border-color: var(--primary-color);
}

.focus\:text-primary:focus {
  color: var(--primary-color);
}

.focus\:bg-primary:focus {
  background: var(--primary-color);
}

.focus\:border-primary:focus {
  border-color: var(--primary-color);
}

.text-helpdesk {
  color: var(--helpdesk-color);
}

.showLoader:after {
  border: 4px solid var(--color-in-primary);
  border-top: 4px solid var(--primary-color);
}

.logincomponent {
  height: 100vh;
  background-image: url(/images/BS_LoginBackground.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.logincomponent:before {
  content: "";
  background-color: var(--tw-black);
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 0;
  opacity: 0.8;
}

.btn-primary {
  background: var(--primary-color);
  color: #fff;
}

.btn-primary.btn-outline {
  border-color: var(--primary-color);
  color: #009fe3;
  background: white;
}

.btn-primary.btn-outline:hover {
  background: var(--primary-color);
  color: var(--color-in-primary);
}

.btn-primary.btn-solid:hover {
  background: var(--primary-color);
}

/*Datepicker styling*/
.dp1 .myDpIconLeftArrow,
.dp1 .myDpIconRightArrow,
.dp1 .myDpHeaderBtn {
  color: var(--tw-black);
  font-size: 14px;
  font-weight: 600;
}

.dp1 .myDpHeaderBtn:focus,
.dp1 .myDpMonthLabel:focus,
.dp1 .myDpYearLabel:focus {
  color: var(--tw-black);
}

.dp1 .myDpHeaderBtnEnabled:hover,
.dp1 .myDpMonthLabel:hover,
.dp1 .myDpYearLabel:hover {
  color: var(--primary-color);
}


.dp1 .myDpWeekDayTitle {
  background-color: #fff;
  border-bottom: 1px solid #E6E6E6;
  color: var(--gray-700);
}

.dp1 .myDpDaycellWeekNbr {
  border-right: 0px;
  color: var(--gray-700);
  border-bottom: 1px solid var(--gray-500);
}

.dp1 .myDpWeekDayTitleWeekNbr {
  opacity: 0;
  border-right: 0px;
}

.dp1 .myDpCalTable th.myDpWeekDayTitleWeekNbr {
  border-right: 0px;
}

.dp1 .myDpCalTable td.myDpDaycellWeekNbr {
  border-right: 0px;
}

.dp1 .myDpMarkCurrDay {
  color: var(--color-in-primary);
  background: var(--primary-color);
  border-radius: 100px;
  border-bottom: 0px;
  padding: 7px;
}

.dp1 .myDpMarkCurrDay.myDpHighlight {
  color: var(--color-in-primary);
}

.dp1 .myDpSelectedMonth,
.dp1 .myDpSelectedYear {
  color: var(--color-in-primary);
  background: var(--primary-color);
}

.dp1 .myDpSelectedMonth .myDpMonthValue {
  color: var(--color-in-primary);
  border-bottom: 0px;
  font-weight: 600;
}

.dp1 .myDpSelectedYear .myDpMarkCurrYear {
  color: var(--color-in-primary);
  border-bottom: 0px;
  font-weight: 600;
}

.dp1 .myDpDisabled {
  color: var(--gray-700)
}

.dp1 .myDpHighlight {
  color: var(--primary-color);
  text-decoration: underline;
}


.dp1 .monthYearSelBar {
  background: #fff;
}

.dp1 .myDpDaycell {
  background: #fff;
  border-bottom: 1px solid var(--gray-500);
  position: static;
  font-size: 11px;
  font-weight: 600;
}

.dp1 .myDpDaycell span {
  display: inline-block;
  padding: 7px;
  min-width: 28px;
  border: 1px solid transparent
}


.dp1 .myDpSelectedDay span {
  border: 1px solid var(--primary-color);
  border-radius: 100px;
  color: var(--primary-color);
}

.dp1 .myDpSelectedDay span.myDpMarkCurrDay {
  border: 1px solid var(--primary-color);
  border-radius: 100px;
  color: var(--color-in-primary);
}


.dp1 .myDpSelectedDay.myDpRangeColor span {
  border: none;
  border-radius: 100px;
  color: var(--color-in-primary);
  padding: 7px;
}

.dp1 .myDpDaycell.myDpRangeColor {
  background-color: var(--primary-color);
  color: var(--color-in-primary);
}


.dp1 .myDpDaycell.myDpRangeColor .myDpHighlight {
  color: var(--color-in-primary);
}

.dp1 .myDpPrevMonth, .dp1 .myDpNextMonth {
  color: var(--gray-700)
}

.dp1 .myDpCurrMonth, .dp1 .myDpMonthcell, .dp1 .myDpYearcell {
  color: var(--tw-black)
}

/*--{Border-radius}--*/
.input-border-radius {
  border-radius: var(--tw-rounded-lg)
}

.textarea-border-radius {
  border-radius: var(--tw-rounded-none);
}

.btn-border-radius {
  border-radius: var(--tw-rounded-full)
}

/*cards border-radius*/
.card-border-radius {
  border-radius: var(--tw-rounded-lg)
}

.small-bottom-bar:before {
  border-radius: var(--tw-rounded-lg)
}

.big-bottom-bar:before {
  border-radius: var(--tw-rounded-lg)
}


/*Class used by the highlight directive, to mark matching text*/
.text-highlight {
  color: var(--primary-color)
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
